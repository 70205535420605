import React, { useState } from "react";
import { FLAVOR_COLORS } from "../flavor-colors";

import Layout from "./layout";
import { PpDetail } from "./pp-detail";
import SEO from "./seo";
import { TosDetail } from "./tos-detail";

const CONTENT = {
  tos: {
    title: "Terms of Service",
    from: "Valid from February 26, 2019",
    bullets: [
      "I am not the owner of any content emitted by any radio station. I just provide that content through the application.",
      "The broadcasts provided are not changed in any way and they are responsability of their own radio station.",
      "In the event of a given radio station requires their broadcasting to be removed from this application, it will be removed right away.",
      "In the future new radio stations can be added or removed.",
      "Broadcasting information such as artist, song, lyrics and so on are dependent of other services availability, and therefore it may not be correct or may be missing at some times",
      "Broadcast content is not sold in any moment. All subscriptions unlock application usage for a limited period and they are not related to radio stations content.",
    ],
  },
  pp: {
    title: "Privacy policy",
    from: "Valid from February 26, 2019",
    bullets: [
      "The application keeps record of favourite stations when requested by the user. This information is stored locally, it is not encrypted, and it is not shared or used outside the application.",
    ],
  },
};

const TosPage = ({ appName, countryCode }) => {
  const baseClassname = "px-6 py-2 font-bold rounded cursor-pointer ml-2 mr-2";
  const selectedClassname = `${baseClassname} bg-white`;
  const unselectedClassname = `${baseClassname} text-white border`;
  const [selected, setSelected] = useState("pp");

  const { from, bullets } = CONTENT[selected];

  return (
    <Layout countryCode={countryCode} appName={appName} logoMaxWidth={220}>
      <SEO title={appName} />
      <div class="flex justify-center mb-16">
        <span
          class={selected === "pp" ? selectedClassname : unselectedClassname}
          onClick={() => setSelected("pp")}
        >
          Privacy policy
        </span>
        <span
          class={selected === "tos" ? selectedClassname : unselectedClassname}
          onClick={() => setSelected("tos")}
        >
          Terms of Service
        </span>
      </div>
      <div className="text-white text-center mb-8 font-bold">{from}</div>
      <div className="list-decimal">
        {bullets.map(b => {
          return <li className="text-white mb-2">{b}</li>;
        })}
      </div>
      <div className="flex flex-col items-center overflow-hidden py-8">
        <div
          style={{
            backgroundColor: FLAVOR_COLORS[countryCode] || FLAVOR_COLORS.pt,
            width: "100%",
            height: "2px",
          }}
        ></div>
      </div>
      <h2 className="text-white text-center font-bold">In more detail</h2>
      <div className="markdown-content py-8">
        {selected === "pp" ? (
          <PpDetail appName={appName} />
        ) : (
          <TosDetail appName={appName} />
        )}
      </div>
      <div className="flex flex-col items-center overflow-hidden">
        <div
          style={{
            backgroundColor: FLAVOR_COLORS[countryCode] || FLAVOR_COLORS.pt,
            width: "100%",
            height: "2px",
          }}
        ></div>
      </div>
    </Layout>
  );
};

export default TosPage;
